import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getFullImageUrl, SOURCES } from 'client/utils/image-helpers';
import { Link } from 'site-modules/shared/components/link/link';
import { Image } from 'site-modules/shared/components/image/image';
import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';
import { NO_VEHICLE_BLUE_EDMUNDS_LOGO } from 'site-modules/shared/constants/images';

import './column-news-widget-card.scss';

export function ColumnNewsWidgetCard({ entry, isVideoCard, isNewsArticleEntry, className }) {
  let cardTitle;
  let url;
  let image;
  const onErrorImage = getFullImageUrl(NO_VEHICLE_BLUE_EDMUNDS_LOGO, IMAGE_SIZES.xs);

  if (isVideoCard) {
    ({ thumbnailURL: image, videoTitle: cardTitle, url } = entry);
  } else if (isNewsArticleEntry) {
    ({ title: cardTitle, image, relativeUrl: url } = entry);
  } else {
    ({ url, 'media-image': image, 'promo-text': cardTitle } = entry);
  }

  if (!cardTitle || cardTitle === '&nbsp;') {
    cardTitle = entry.title;
  }

  const imageUrl = isVideoCard ? getFullImageUrl(image) : image;

  return (
    <Link
      to={isVideoCard ? `${url}#videos` : url}
      className={classnames('column-news-widget-card d-flex align-items-center p-0_5 rounded', className)}
    >
      <Image
        imageId={imageUrl}
        source={SOURCES.MEDIA}
        alt=""
        className="mh-100 rounded"
        height={90}
        width={135}
        lazy
        errorImage={onErrorImage}
      />
      <div className="d-flex flex-column justify-content-center align-items-start pl-1_5">
        {isVideoCard && (
          <div className="video-card-badge d-inline-flex align-items-center mb-0_5 py-0_25 px-0_75 rounded size-12 fw-bold text-primary">
            <span className="icon-play_circle_outline size-12 mr-0_25" aria-hidden /> Watch Review
          </div>
        )}
        <div className="card-title fw-bold mb-0">{cardTitle}</div>
      </div>
    </Link>
  );
}

ColumnNewsWidgetCard.propTypes = {
  entry: PropTypes.shape({}),
  isVideoCard: PropTypes.bool,
  isNewsArticleEntry: PropTypes.bool,
  className: PropTypes.string,
};

ColumnNewsWidgetCard.defaultProps = {
  entry: {},
  isVideoCard: false,
  isNewsArticleEntry: false,
  className: '',
};
