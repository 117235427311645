import React from 'react';
import dateFormat from 'dateformat';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import { ArticleContent } from 'site-modules/shared/components/preprod-articles/article-content';
import { ArticlePhoto } from 'site-modules/shared/components/preprod-articles/article-photo';
import { ArticleVideo } from 'site-modules/shared/components/preprod-articles/article-video';

export function getChildren({ article, key, suppressMedia, hideDate }) {
  const date = dateFormat(article.publishDate, 'mmmm dS, yyyy');

  return article.content.map((contentChunk, index) => {
    const chunkKey = `${key}-${index}`;
    switch (contentChunk.type) {
      case 'text': {
        return <ArticleContent key={chunkKey} {...contentChunk} author={article.author} date={hideDate ? '' : date} />;
      }
      case 'photos': {
        if (!index && suppressMedia) {
          return null;
        }
        return <ArticlePhoto key={chunkKey} links={contentChunk.links} allocateSpace lazyLoad imageSize={815} />;
      }
      case 'video': {
        if (!index && suppressMedia) {
          return null;
        }
        return <ArticleVideo key={chunkKey} youtubeVideoId={contentChunk.videoIds['youtube-videoid']} />;
      }
      case 'ad': {
        return (
          <div xs={12} lg={8} className="my-1" key={chunkKey}>
            <NativeAd
              adName={contentChunk.content.adName}
              nativeStyle={contentChunk.content.nativeStyle}
              position={contentChunk.content.position}
              showLabel={contentChunk.content.showLabel}
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  });
}

export function getContentFreshcoreAd(isMobile) {
  return {
    type: 'ad',
    content: {
      adName: isMobile ? 'mmrect' : 'native',
      nativeStyle: isMobile ? 'mediumrectangle' : 'vdp',
      position: '1',
      showLabel: true,
    },
    entry: 3,
  };
}
