import { orderBy, findIndex } from 'lodash';

const TYPE_CATEGORY = {
  TRUCK: 'truck',
  ELECTRIC: 'electric',
};

export const SECTION_MAP = {
  DRIVING: { name: 'Performance', question: 'How does it drive?', key: 'driving' },
  COMFORT: { name: 'Comfort', question: 'How comfortable is it?', key: 'comfort' },
  INTERIOR: { name: 'Interior', question: 'How’s the interior?', key: 'interior' },
  TECHNOLOGY: { name: 'Technology', question: 'How’s the tech?', key: 'technology' },
  UTILITY: { name: 'Storage', question: 'How’s the storage?', key: 'utility' },
  MPG: { name: 'Fuel Economy', question: 'How’s the fuel economy?', key: 'mpg' },
  VALUE: { name: 'Value', question: 'Is it a good value?', key: 'value' },
  WILDCARD: { name: 'Wildcard', key: 'wildcard' },
};

export function getModelSectionMap(modelName, edmundsTypeCategories) {
  const typeCategories = edmundsTypeCategories ? Object.keys(edmundsTypeCategories) : [];
  const modelSectionMap = {
    ...SECTION_MAP,
    DRIVING: { ...SECTION_MAP.DRIVING, question: `How does the ${modelName} drive?` },
    COMFORT: { ...SECTION_MAP.COMFORT, question: `How comfortable is the ${modelName}?` },
    VALUE: { ...SECTION_MAP.VALUE, question: `Is the ${modelName} a good value?` },
  };

  if (typeCategories.includes(TYPE_CATEGORY.TRUCK)) {
    modelSectionMap.UTILITY = {
      ...SECTION_MAP.UTILITY,
      name: 'Towing and Storage',
      question: 'How are the towing and storage?',
    };
  }

  if (typeCategories.includes(TYPE_CATEGORY.ELECTRIC)) {
    modelSectionMap.MPG = {
      ...SECTION_MAP.MPG,
      name: 'Range and Efficiency',
      question: 'How are the range and efficiency?',
    };
  }

  return modelSectionMap;
}

/**
 * Returns an array where each element is an array of sections and embed after these sections
 * @returns {{sections: Array, embed: String}[]}
 */
export function breakSectionsWithEmbeds(sections, embedBeforeMap) {
  const sortedEmbedMap = orderBy(
    embedBeforeMap.filter(({ key: embedKey }) => !!sections.find(({ key }) => key === embedKey)),
    ({ key }) => findIndex(sections, { key })
  );

  const { currentSections: resultCurrentSections, result: resultArray } = sortedEmbedMap.reduce(
    ({ currentSections, result }, { key, embed }) => {
      const sectionIndex = findIndex(currentSections, { key });

      return {
        result: [...result, { sections: currentSections.slice(0, sectionIndex), embedAfter: embed }],
        currentSections: currentSections.slice(sectionIndex),
      };
    },
    { currentSections: sections, result: [] }
  );

  return [...resultArray, { sections: resultCurrentSections }].filter(
    ({ sections: resultSections, embedAfter }) => embedAfter || resultSections.length
  );
}
