import PropTypes from 'prop-types';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { parseContent } from 'client/data/cms/content';
import { sortByNumber } from 'site-modules/shared/utils/sorting';
import { fetchContent, fetchContentWithContent } from 'client/data/cms/fetch-content';
import { buildNoSubmodelVehiclePath } from './vehicle';

export const VehicleComparisonsEntities = {
  ComparisonsContent: PropTypes.shape({
    comparisons: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        vehicle: PropTypes.shape({
          make: PropTypes.string,
          model: PropTypes.string,
          year: PropTypes.string,
          style: PropTypes.string,
        }),
      })
    ),
  }),
  WhatsNewContent: PropTypes.arrayOf(PropTypes.string),
};

export const VehicleQuestionsEntities = {
  QuestionsContent: PropTypes.shape({
    vehicle: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      year: PropTypes.string,
      style: PropTypes.string,
    }),
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        content: PropTypes.string,
      })
    ),
  }),
};

/**
 * Returns path for VehicleDetails
 * @param obj {{
 *  make: (string)
 *  model: (string)
 *  year: (string)
 * }} - object containing make, model, year
 *
 * @returns {string}
 */
export function buildDetailsPath(obj) {
  return obj ? `${buildNoSubmodelVehiclePath(obj)}.details` : null;
}

/**
 * Returns path for Vehicle More About
 * @param obj {{
 *  make: (string)
 *  model: (string)
 *  year: (string)
 * }} - object containing make, model, year
 *
 * @returns {string}
 */
export function buildAboutPath(obj) {
  return `${buildDetailsPath(obj)}.about`;
}

/**
 * Returns path for VehicleComparisonModel
 * @param obj {{
 *  make: (string)
 *  model: (string)
 *  year: (string)
 * }} - object containing make, model, year
 *
 * @returns {string}
 */
export function buildComparisonsPath(obj) {
  return `${buildDetailsPath(obj)}.comparisons`;
}

/**
 * Returns path for VehicleQuestionsModel
 * @param obj {{
 *  make: (string)
 *  model: (string)
 *  year: (string)
 * }} - object containing make, model, year
 *
 * @returns {string}
 */
export function buildQuestionsPath(obj) {
  return `${buildDetailsPath(obj)}.questions`;
}

/**
 * @param obj {{
 *  make: (string)
 *  model: (string)
 *  year: (string)
 * }} - object containing make, model, year
 *
 * @returns {string}
 */
export function buildWhatsNewPath(obj) {
  return `${buildDetailsPath(obj)}.whatsNew`;
}

function getMetadataValue(entry, key) {
  if (entry && entry.hasMetadata) {
    return entry.hasMetadata(key) ? entry.metadata(key).value() : null;
  }
  return null;
}

function getEntry(content, id) {
  return content.hasChild(id) ? content.child(id) : null;
}

function getModelComparison(content, number) {
  const comparison = getEntry(content, `model-vs-model-${number}`);
  const comparisonText = getMetadataValue(comparison, 'content');

  return comparisonText && comparisonText.trim().length
    ? {
        content: comparisonText,
        vehicles: comparison.vehicles,
      }
    : null;
}

function getModelComparisons(content) {
  const compArr = [];
  if (getModelComparison(content, 1)) {
    compArr.push(getModelComparison(content, 1));
  }
  if (getModelComparison(content, 2)) {
    compArr.push(getModelComparison(content, 2));
  }
  if (getModelComparison(content, 3)) {
    compArr.push(getModelComparison(content, 3));
  }
  return compArr.length ? compArr : null;
}

function transformComparisons(apiResponse) {
  const content = parseContent(apiResponse);
  const comparisonContent = getEntry(content, 'comparison');

  const comparisonObj = {
    comparisons: getModelComparisons(comparisonContent),
  };

  return comparisonObj.comparisons ? comparisonObj : null;
}

function getVehicleQuestions(content) {
  const questionsArr = [];

  content.forEach(val => {
    const answer = getMetadataValue(val, 'content');
    if (answer && answer.trim().length) {
      questionsArr.push({ id: val.id, content: answer });
    }
  });
  return questionsArr.length ? questionsArr : null;
}

function getVehicle(content) {
  const comparisonContent = getEntry(content, 'comparison');
  const comparison = getEntry(comparisonContent, 'model-vs-model-1');
  return comparison ? comparison.vehicles[0] : null;
}

function transformQuestions(apiResponse) {
  const content = parseContent(apiResponse);
  const questionsEntry = getEntry(content, 'question-answer');
  const questionsContent = questionsEntry && questionsEntry.children();

  return content && questionsEntry
    ? {
        vehicle: getVehicle(content),
        questions: getVehicleQuestions(questionsContent),
      }
    : null;
}

/**
 * Get what's new data from response.
 * @param {object} apiResponse - response from API.
 *
 * @returns {array}
 */
function getWhatsNew(apiResponse) {
  const whatsNew = [];
  const content = parseContent(apiResponse);
  const whatsNewContent = getEntry(content, 'whats-new');
  const whatsNewMetadata = whatsNewContent.getAllMetadata();

  Object.keys(whatsNewMetadata)
    .sort(sortByNumber)
    .forEach(key => {
      if (key.match(/whats-new/) && whatsNewMetadata[key] && whatsNewMetadata[key].trim().length) {
        whatsNew.push(whatsNewMetadata[key]);
      }
    });

  return whatsNew.length ? whatsNew : null;
}

export function transformDetails(apiResponse) {
  return {
    comparisons: transformComparisons(apiResponse),
    questions: transformQuestions(apiResponse),
    whatsNew: getWhatsNew(apiResponse),
  };
}

export function transformAbout(apiResponse) {
  const content = parseContent(apiResponse);
  const entry = getEntry(content, 'about');
  return entry
    ? {
        vehicleAbout: entry.content,
      }
    : null;
}

export const VehicleDetailsModel = createModelSegment('details', [
  {
    /**
     * makes["{make}"].models["{model}"].years["{year}"].details => { comparisons, questions, whatsNew }
     * makes["{make}"].models["{model}"].years["{year}"].details.comparisons => comparisons
     * makes["{make}"].models["{model}"].years["{year}"].details.questions => questions
     * makes["{make}"].models["{model}"].years["{year}"].details.whatsNew => whatsNew
     *
     */
    path: 'makes["{make}"].models["{model}"].years["{year}"].details',
    resolve(match, context) {
      const { make, model, year } = match;
      const path = `${make}/${model}/${year}/ymm-detail`;

      return fetchContent(path, context)
        .then(apiResponse => transformDetails(apiResponse))
        .catch(() => null);
    },
  },
  {
    /**
     * Treating this data as an mmy-detail but getting from the about atom file
     * makes["{make}"].models["{model}"].years["{year}"].details.about
     *
     */
    path: 'makes["{make}"].models["{model}"].years["{year}"].details.about',
    resolve(match, context) {
      const { make, model, year } = match;
      const path = `${make}/${model}/${year}/about`;

      return fetchContentWithContent(`${path}`, context)
        .then(apiResponse => transformAbout(apiResponse))
        .catch(() => null);
    },
  },
]);
