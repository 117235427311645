import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { CmsEntities } from 'client/data/models/cms';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './ad-slide.scss';

/**
 * Renders carousel ad slide layout
 *
 * @return {ReactElement} Carousel slide layout
 */
export function AdSlide({ adContent, adImageUri, counter, isMobile, ...restProps }) {
  const clickTracker = isMobile
    ? adContent.metadata('clickredirectmobile').value()
    : adContent.metadata('clickredirect').value();
  const sponsor = adContent.metadata('sponsor').value();
  const isClickout = adContent.metadata('linkClickOut').boolean();
  return (
    <div data-index={counter} {...restProps}>
      <div className="slide text-zdepth-1 pl-0_5">
        <div className="ad-unit mt-2 mt-mb-2_5">
          <strong>
            <ContentFragment classes="h1 text-white mb-0_5 mb-md-3_5">
              {adContent.metadata('headercopy').value()}
            </ContentFragment>
          </strong>

          <a
            href={clickTracker}
            className="btn btn-success ad-unit-linkout hidden-sm-down"
            target={isClickout ? '_blank' : null}
          >
            {adContent.metadata('ctacopy').value()}
          </a>
          <a
            href={clickTracker}
            className="btn btn-link btn-sm text-white ad-unit-linkout hidden-md-up p-0"
            target={isClickout ? '_blank' : null}
          >
            {adContent.metadata('ctacopy').value()}
          </a>
        </div>
        <div className="photo" style={{ backgroundImage: `url(${adImageUri})` }} />
        {!!sponsor.length && (
          <Fragment>
            <div className="display-6 hidden-sm-down hero-sponsor-disclaimer fw-normal">
              <ContentFragment classes="text-white small" componentToUse="p">
                {`Photo sponsored by ${sponsor}`}
              </ContentFragment>
            </div>
            <div className="display-6 hidden-md-up hero-sponsor-disclaimer fw-normal">
              <ContentFragment classes="text-white small" componentToUse="p">
                {`Photo sponsored by ${sponsor}`}
              </ContentFragment>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

AdSlide.propTypes = {
  adContent: CmsEntities.Content,
  adImageUri: PropTypes.string.isRequired,
  counter: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
};

AdSlide.defaultProps = {
  adContent: DEFAULT_CONTENT,
  isMobile: false,
};
