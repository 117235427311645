import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl, SOURCES } from 'client/utils/image-helpers';
import { ArticleHeroCarousel } from 'site-modules/shared/components/editorial/article-hero-carousel/article-hero-carousel';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';

export function ArticlePhoto({ links, allocateSpace, lazyLoad, imageSize }) {
  if (links.length !== 1) {
    return <ArticleHeroCarousel carouselClasses="article-photo my-1" slides={links} />;
  }
  const { href, title, captionTranscript } = links[0];

  const image = (
    <img
      className="w-100"
      src={getImageUrl(href, imageSize, SOURCES.DAM)}
      alt={title || ''}
      title={title}
      loading={lazyLoad ? 'lazy' : undefined}
    />
  );

  return (
    <div className="my-1">
      {allocateSpace ? <AspectRatioContainer aspectRatio="3:2">{image}</AspectRatioContainer> : image}
      {!!captionTranscript && <div className="mt-0_25 xsmall text-gray">{captionTranscript}</div>}
    </div>
  );
}

ArticlePhoto.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      captionTranscript: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  imageSize: PropTypes.number,
  allocateSpace: PropTypes.bool,
  lazyLoad: PropTypes.bool,
};

ArticlePhoto.defaultProps = {
  imageSize: 1600,
  allocateSpace: false,
  lazyLoad: false,
};
