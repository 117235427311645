import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { HeroCarousel } from 'site-modules/shared/components/hero-carousel/hero-carousel';

export function EditorialHeroCarouselWrapper({ slides, setCurrentSlide, carouselClasses }) {
  return (
    <HeroCarousel
      autoplay={false}
      lazyLoad
      className={classnames('editorial-hero-carousel', carouselClasses)}
      data-tracking-parent="edm-entry-editorial-hero-carousel"
      slides={slides}
      beforeChange={setCurrentSlide}
    />
  );
}

EditorialHeroCarouselWrapper.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.element).isRequired,
  setCurrentSlide: PropTypes.func,
  carouselClasses: PropTypes.string,
};

EditorialHeroCarouselWrapper.defaultProps = {
  setCurrentSlide: null,
  carouselClasses: null,
};
