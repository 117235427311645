import React from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Video } from 'site-modules/shared/components/video/index';

export function ArticleVideo({ youtubeVideoId }) {
  let type = null;
  let videoId = null;
  if (youtubeVideoId) {
    type = 'Youtube';
    videoId = youtubeVideoId;
  }
  return (
    !!videoId && (
      <Row className="justify-content-center">
        <Col xs={12} lg={9} className="my-1">
          <Video videoId={videoId} type={type} />
        </Col>
      </Row>
    )
  );
}

ArticleVideo.propTypes = {
  youtubeVideoId: PropTypes.string,
};

ArticleVideo.defaultProps = {
  youtubeVideoId: '',
};
