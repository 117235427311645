import React from 'react';
import PropTypes from 'prop-types';

import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';
import { ArticleTitle } from 'site-modules/shared/components/editorial/article-title/article-title';
import { EditorialHeroCarousel } from 'site-modules/shared/components/editorial/editorial-hero-carousel/editorial-hero-carousel';
import './article-hero-carousel.scss';

export function ArticleHeroCarousel({ title, subtitle, slides, carouselClasses, imageSize }) {
  return (
    <EditorialHeroCarousel
      imageSize={imageSize}
      slides={slides}
      classes="align-items-start"
      carouselClasses={carouselClasses}
    >
      <ArticleTitle title={title} subtitle={subtitle} />
    </EditorialHeroCarousel>
  );
}

ArticleHeroCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  carouselClasses: PropTypes.string,
  imageSize: PropTypes.number,
};

ArticleHeroCarousel.defaultProps = {
  slides: [],
  title: '',
  subtitle: '',
  carouselClasses: '',
  imageSize: IMAGE_SIZES.max,
};
