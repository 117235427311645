import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { FirstContentArticleEntity } from 'client/data/models/editorial-review';
import { SCROLL_DURATION, SCROLL_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { EdmundsLogo } from 'site-modules/shared/components/edmunds-logo/edmunds-logo';
import { FindIncentivesBanner } from 'site-modules/shared/components/core-page/find-incentives-banner/find-incentives-banner';
import { IncentivesAd } from 'site-modules/shared/components/native-ad/incentives-ad/incentives-ad';
import { ArticlePhoto } from 'site-modules/shared/components/preprod-articles/article-photo';
import { ReadyForEv, ReadyForEvTreehouse } from 'site-modules/shared/components/ready-for-ev/ready-for-ev';
import { TreehousePromotionCheck } from 'site-modules/shared/components/treehouse-promotion/treehouse-promotion-check';

import './pre-prod-content-articles.scss';

const EDMUNDS_SAYS = 'Edmunds says';

export function PreProdContentArticles({
  articles,
  titleClassName,
  collapseBodyText,
  hasEdmundsSaysIcon,
  hasPhotos,
  photoProps,
  isMobile,
  showIncentiveAd,
  incentivesAdProps,
  hasTreehousePromo,
}) {
  if (!get(articles, 'length')) {
    return null;
  }

  return articles.map(({ title, content, links }, index) => {
    const key = `pre-prod-article-${index}`;
    const paragraphs = content
      .split('</p>')
      .filter(paragraph => paragraph)
      .map(paragraph => `${paragraph}</p>`);
    const hasCollapsedPart = collapseBodyText && paragraphs.length > 1;
    const isEdmundsSays = title === EDMUNDS_SAYS;

    const readyForEvPreprod = (
      <ReadyForEv heading="Am I Ready for an EV?" className="bg-white rounded-12 px-1_5 py-1_5" />
    );

    return (
      <Fragment key={key}>
        {hasTreehousePromo && isEdmundsSays && (
          <TreehousePromotionCheck fallback={readyForEvPreprod}>
            <div className="preprod-treehouse-promotion bg-cool-gray-90 px-1 py-1 rounded-12 mb-2">
              <ReadyForEvTreehouse
                className="bg-white rounded-12 px-1_5 py-1_5 mx-auto"
                heading="Am I Ready for an EV?"
                creativeId="treehouse-promotion"
              />
            </div>
          </TreehousePromotionCheck>
        )}
        <div className={classnames('article text-gray-darker mt-0_5', { 'mb-1': !links.length || !hasPhotos })}>
          {hasCollapsedPart && <ScrollElement id={`scrollTop-${key}`} />}
          <h3 className={classnames('mb-0_5', titleClassName)}>
            {hasEdmundsSaysIcon && isEdmundsSays && <EdmundsLogo className="mr-0_5" alt="" path={null} inline />}
            {title}
          </h3>
          <ContentFragment
            classes={classnames('pre-prod-content text-gray-darker', { 'has-collapsed': hasCollapsedPart })}
          >
            {hasCollapsedPart ? paragraphs[0] : content}
          </ContentFragment>
          {hasCollapsedPart && (
            <ContentCollapse
              isEclipseFade={false}
              btnClasses="px-0_5 py-0 size-16 text-primary-darker"
              btnContainerClasses="py-0"
              justify="justify-content-start"
              showBtnBelow
              btnTextClasses="fw-normal"
              classes="size-16"
              resetParams={{
                offset: SCROLL_OFFSET,
                duration: SCROLL_DURATION,
                to: `scrollTop-${key}`,
              }}
            >
              <ContentFragment classes="pre-prod-content text-gray-darker mt-1">
                {paragraphs.slice(1).join('')}
              </ContentFragment>
            </ContentCollapse>
          )}
          {index === 0 && isMobile && showIncentiveAd && (
            <IncentivesAd
              wrapperClass="px-lg-0 my-2"
              mobile={isMobile}
              placeholderComponent={FindIncentivesBanner}
              placeholderComponentProps={incentivesAdProps}
              showOnlySiteServed
            />
          )}
          {hasPhotos && !!links.length && <ArticlePhoto links={links} {...photoProps} />}
        </div>
      </Fragment>
    );
  });
}

PreProdContentArticles.propTypes = {
  articles: PropTypes.arrayOf(FirstContentArticleEntity),
  titleClassName: PropTypes.string,
  collapseBodyText: PropTypes.bool,
  hasEdmundsSaysIcon: PropTypes.bool,
  hasPhotos: PropTypes.bool,
  photoProps: PropTypes.shape(),
  incentivesAdProps: PropTypes.shape(),
  isMobile: PropTypes.bool,
  showIncentiveAd: PropTypes.bool,
  hasTreehousePromo: PropTypes.bool,
};

PreProdContentArticles.defaultProps = {
  articles: null,
  titleClassName: 'heading-4',
  collapseBodyText: false,
  hasEdmundsSaysIcon: false,
  hasPhotos: true,
  photoProps: null,
  incentivesAdProps: null,
  isMobile: false,
  showIncentiveAd: false,
  hasTreehousePromo: false,
};
