import { get } from 'lodash';

export function getEditorialRatingParams(editorialRating) {
  if (editorialRating <= 7.1) {
    return { text: 'Below Average', badgeClassName: 'below-avg' };
  }
  if (editorialRating <= 7.6) {
    return { text: 'Average', badgeClassName: 'avg' };
  }
  if (editorialRating <= 8) {
    return { text: 'Good' };
  }
  return { text: 'Great', badgeClassName: 'great' };
}

export function getCurrentStyleId({ styles, sharedTrimName }) {
  return (styles && get(styles.find(({ trim }) => trim === sharedTrimName), 'id')) || get(styles, '[0].id');
}
