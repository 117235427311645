import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ArticleAuthor } from './article-author';

export function ArticleContent({ title, subTitle, content, author, date }) {
  return (
    !!content && (
      <div className="mb-md-1">
        {!!title && (
          <Fragment>
            <h2 className="heading-3 mb-0_25 mb-md-0_5">{title}</h2>
            <h3 className="subtitle heading-5 mb-0_75 mb-md-0">{subTitle}</h3>
            {!!author && <ArticleAuthor author={author} date={date} />}
          </Fragment>
        )}
        <ContentFragment classes="review-content text-gray-darker">{content}</ContentFragment>
      </div>
    )
  );
}

ArticleContent.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  content: PropTypes.string,
  author: PropTypes.shape({
    photoPath: PropTypes.string,
    authorLink: PropTypes.string,
    authorName: PropTypes.string,
    authorTitle: PropTypes.string,
  }),
  date: PropTypes.string,
};

ArticleContent.defaultProps = {
  title: null,
  subTitle: null,
  content: null,
  author: null,
  date: null,
};
